import React from 'react'
import ding from './ding.mp3'

class Music extends React.Component {  
  render() {
    return (
      <div>
        <span>{console.log('Here is music')}</span>
        <audio src="https://sndup.net/5k2w/ding.mp3" autoPlay />
        <audio src={ding} autoPlay />
      </div>
    )
  }
}

export default Music